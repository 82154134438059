<template>
  <div>
    <div v-if="!$apollo.loading">
      <h3 style="margin-bottom:30px;">Cliente: {{user | userDocument("NICKNAME")}} 
        <span v-if="user.documents.filter(d=> d.type == 'BLACKLIST').length > 0" id="blacklist-flag">
          <feather-icon size="25" class="mr-1" icon="AlertTriangleIcon" />ESTE CLIENTE ESTÁ NA BLACKLIST
        </span>
        <span v-if="user.documents.filter(d=> d.type == 'GOODLIST').length > 0" id="good-client-flag">
          <span class="plate">
            💎 TRATE BEM ESTE CLIENTE
          </span>
        </span>
        <span v-if="user.documents.filter(d=> d.type == 'INTERNAL').length > 0" id="demo-client-flag">
          <span class="plate">
            ⚠️ CLIENTE DEMO
          </span>
        </span>
        <span v-if="user.documents.filter(d=> d.type == 'NOBONUS').length > 0" id="demo-client-flag">
          <span class="plate">
            ⚠️ SEM BONUS
          </span>
        </span>
      </h3>
      <client-sticky-notes :notes="user_notes" @refreshNotes="refreshIdentity()"/>
      <client-list-info :wallets="wallets" :user="user" @openButtonSidebar="openButtonSidebar" @refreshUserData="refreshIdentity()" @showRelatedModal="showRelatedModal"/>
      <client-stats v-if="false" :movements="movements"/>
      <client-transactions @openSidebar="openDepositSidebar" :orders="{withdraws: withdraw_orders,pending_deposits: pending_deposits,confirmed_deposits: confirmed_deposits}" :key="this.key" :usedBonus="user_used_promotions" />
      <client-movements :userWallets="walletsToBeSearched" :uWall="wallets" :userId="this.$route.params.clientId"/>
      <general-clients-list v-if="wallets" class="w-full" :searchedUsers="relatedUsers" :addInfo="{key:'related',data:user_with_all_relations.user_relations}" :contextActions="['remove-relation']" @removeRelation="removeUserRelation"/>
      <general-groups-list :key="rKey" :addInfo="'userPage'" :searchedGroups="user_groups"@showGroupModal="showGroupModal"/>

      <div v-if="user.documents.filter(d=> d.type.indexOf('FILE') > -1)">
        <b-row >
          <b-col
            lg="4"
            md="12"
          >
            <b-card v-if="user.documents.filter(d=> d.type.indexOf('FILE_CPF_FRONT') > -1)[0]" class="calc" title="DOC FRENTE">
              <img width="100%" :src="'https://bingolar-documents.s3.sa-east-1.amazonaws.com'+user.documents.filter(d=> d.type.indexOf('FILE_CPF_FRONT') > -1)[0].value" alt="">
              <template #footer>
                <div  v-if="user.documents.filter(d=> d.type.indexOf('FILE_CPF_FRONT') > -1)[0].status == 'PENDING'" class="d-flex">
                  <b-button class="act-button" @click="alterDoc(user.documents.filter(d=> d.type.indexOf('FILE_CPF_FRONT') > -1)[0].id, 'VALIDATED')" variant="gradient-success">Aprovar</b-button>
                  <b-button class="act-button" @click="alterDoc(user.documents.filter(d=> d.type.indexOf('FILE_CPF_FRONT') > -1)[0].id, 'NO_VALID')" variant="gradient-danger">Reprovar</b-button>
                </div>
                <div v-else class="text-center">
                  {{user.documents.filter(d=> d.type.indexOf('FILE_CPF_FRONT') > -1)[0].status}}
                </div>
              </template>
            </b-card>
          </b-col>
          <b-col
            lg="4"
            md="12"
          >
            <b-card v-if="user.documents.filter(d=> d.type.indexOf('FILE_CPF_BACK') > -1)[0]" class="calc" title="DOC VERSO">
              <img width="100%" :src="'https://bingolar-documents.s3.sa-east-1.amazonaws.com'+user.documents.filter(d=> d.type.indexOf('FILE_CPF_BACK') > -1)[0].value" alt="">
              <template #footer>
                <div v-if="user.documents.filter(d=> d.type.indexOf('FILE_CPF_BACK') > -1)[0].status == 'PENDING'" class="d-flex">
                  <b-button class="act-button" @click="alterDoc(user.documents.filter(d=> d.type.indexOf('FILE_CPF_BACK') > -1)[0].id, 'VALIDATED')" variant="gradient-success">Aprovar</b-button>
                  <b-button class="act-button" @click="alterDoc(user.documents.filter(d=> d.type.indexOf('FILE_CPF_BACK') > -1)[0].id, 'NO_VALID')" variant="gradient-danger">Reprovar</b-button>
                </div>
                <div v-else class="text-center">
                  {{user.documents.filter(d=> d.type.indexOf('FILE_CPF_BACK') > -1)[0].status}}
                </div>
              </template>
            </b-card>
          </b-col>
          <b-col
            lg="4"
            md="12"
          >
            <b-card v-if="user.documents.filter(d=> d.type.indexOf('FILE_SELFIE') > -1)[0]" class="calc" title="SELFIE">
              <img width="100%" :src="'https://bingolar-documents.s3.sa-east-1.amazonaws.com'+user.documents.filter(d=> d.type.indexOf('FILE_SELFIE') > -1)[0].value" alt="">
              <template #footer>
                <div v-if="user.documents.filter(d=> d.type.indexOf('FILE_SELFIE') > -1)[0].status == 'PENDING'" class="d-flex">
                  <b-button class="act-button" @click="alterDoc(user.documents.filter(d=> d.type.indexOf('FILE_SELFIE') > -1)[0].id, 'VALIDATED')" variant="gradient-success">Aprovar</b-button>
                  <b-button class="act-button" @click="alterDoc(user.documents.filter(d=> d.type.indexOf('FILE_SELFIE') > -1)[0].id, 'NO_VALID')" variant="gradient-danger">Reprovar</b-button>
                </div>
                <div v-else class="text-center">
                  {{user.documents.filter(d=> d.type.indexOf('FILE_SELFIE') > -1)[0].status}}
                </div>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <client-deposit-sidebar ref="depositSidebar" :deposit="depositObj" @refreshOrders="refreshOrders()"/>
      <client-change-name-sidebar v-if="relatedUsers" ref="changeNameSidebar" @refreshUserData="refreshIdentity()"/>
      <client-add-promotion-sidebar :userWallets="wallets" :lastDeposit="confirmed_deposits.data[0]" ref="addPromotionSidebar"/>
      <client-add-notes-sidebar ref="addNotesSidebar" @refreshNotes="refreshIdentity()"/>
      <client-add-related-modal ref="relatedModal" :alreadyRelated="relatedUsers" @refreshUserData="refreshIdentity()"/>
      <client-add-group-modal ref="groupModal" :alreadyAdded="user_groups" @refreshGroups="refreshGroups"/>
      <client-btag-sidebar ref="btagSidebar" :bTag="user.documents.filter(d=> d.type.indexOf('CLICKID') > -1)[0]" @refreshUserData="refreshIdentity()"/>
    </div>
    <div v-else>
      <logo-loader-animation/>
    </div>
  </div>
</template>

<script>
import { BCard, BTabs, BTab,BButton, BCardText, BLink, BFormInput, BCol, BRow,BModal} from 'bootstrap-vue'

import ClientDepositSidebar from "@/components/clients/ClientDepositSidebar.vue"
import ClientAddNotesSidebar from "@/components/clients/ClientAddNotesSidebar.vue"
import ClientChangeNameSidebar from "@/components/clients/ClientChangeNameSidebar.vue"
import ClientAddPromotionSidebar from "@/components/clients/ClientAddPromotionSidebar.vue"
import ClientBtagSidebar from "@/components/clients/ClientBtagSidebar.vue"
import GeneralClientsList from "@/components/clients/GeneralClientsList.vue"
import GeneralGroupsList from "@/components/groups/GeneralGroupsList.vue"
import ClientStats from "@/components/clients/ClientStats.vue"
import ClientListInfo from "@/components/clients/ClientListInfo.vue"
import ClientTransactions from "@/components/clients/ClientTransactions.vue"
import ClientMovements from "@/components/clients/ClientMovements.vue"
import ClientStickyNotes from "@/components/clients/ClientStickyNotes.vue"
import ClientAddRelatedModal from "@/components/clients/ClientAddRelatedModal.vue"
import ClientAddGroupModal from "@/components/clients/ClientAddGroupModal.vue"
import LogoLoaderAnimation from "@/components/misc/LogoLoaderAnimation.vue"
import gql from 'graphql-tag'
import {_} from 'vue-underscore';

export default {
  components: {
    BButton, 
    BTab,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BCol,
    BRow,
    BTabs,
    BModal,
    ClientDepositSidebar,
    ClientChangeNameSidebar,
    ClientAddPromotionSidebar,
    ClientStats,
    ClientAddRelatedModal,
    BLink,
    underscore: _,
    ClientAddNotesSidebar,
    ClientListInfo,
    ClientTransactions,
    ClientStickyNotes:ClientStickyNotes,
    GeneralClientsList,
    LogoLoaderAnimation,
    GeneralGroupsList,
    ClientAddGroupModal,
    ClientMovements,
    ClientBtagSidebar
  },
  data () {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Products',
          customClass: 'mb-2 mb-sm-0',
        }
      ],
      user:{id:"0"},
      wallets:null,
      walletsToBeSearched : ["0"],
      depositObj:null,
      movements:null,
      openOrders:null,
      key:0,
      relatedUsers:[],
      withdraw_orders:null,
      rKey:1,
    }
  },
  apollo:{
    user_with_all_relations: {
      query: gql`query($userId:ID!){
        user_with_all_relations(id:$userId){
          id,
          username,
          status,
          created_at,
          documents{
            id,
            type,
            value,
            status
          },
          user_relations{
            id,
            relation_type{
              code
            },
            related_user{
              id,
              username,
              status,
              created_at,
              documents{
                id,
                type,
                value
              }
            },
            related_to_me{
              id,
              username,
              status,
              created_at,
              documents{
                id,
                type,
                value
              }
            }
          },
        }
      }`,
      client: 'identityClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      }
    },
    user_notes: {
      query: gql`query($userId:ID!){
        user_notes(user_id:$userId){
          id,
          title,
          content,
          priority,
          created_at,
          created_by
        }
      }`,
      client: 'identityClient',
      variables () {
        return {
            userId: this.$route.params.clientId,
        }
      }
    },
    user_used_promotions: {
      query: gql`query($userId:ID!){
        user_used_promotions(first:20,user_id:$userId, release_status:"APPLIED"){
          data{
            id,
            transaction_id,
            value_released,
            released_by,
            created_at,
            updated_at,
            promotion{
              id,
              name,
              title,
              wallet_type_id 
            }
          }
        }
      }`,
      client: 'identityClient',
      variables () {
        return {
            userId: this.$route.params.clientId,
        }
      }
    },
    wallets:{
      query: gql `query($userId:String!){
        wallets(first:100,user_id:$userId){
          data{
            user_id,
            wallet_code,
            wallet_type_id,
            balance,
            id
          },
        }
      }`,
      client: 'walletClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      }
    },
    movements:{
      query: gql `query($userId:String, $containsWalletsIds:[String!], $containsMovementTypes:[Int!]){
        movements_with_wallet(first:100,containsWalletsIds:$containsWalletsIds, 
        containsMovementTypes:$containsMovementTypes,
        user_id: $userId){
          data{
            id,
            value,
            movement_type_id,
            wallet_id,
            created_at
          },
        }
      }`,
      client: 'walletClient',
      variables () {
        return {
          containsWalletsIds: this.walletsToBeSearched,
          containsMovementTypes: [1,2,8],
          userId: this.$route.params.clientId,

        }
      }
    },
    withdraw_orders:{
      query: gql `query($userId:ID){
      withdraw_orders: 
        orders(first:15,user_id:$userId, order_type:"WITHDRAW"){
          data{
            id,
            order_type,
            user_id,
            amount,
            status,
            created_at,
            updated_at,
            target_bank{
              bank_name,
              owner_name,
              account,
              agency
            },
            tags{
              key,
              value
            },
            provider{
              code
            }
          },
        }
      }`,
      client: 'cashioClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
    pending_deposits:{
      query: gql `query($userId:ID){
      pending_deposits: 
        orders(first:15,user_id:$userId, order_type:"DEPOSIT", status:"PENDING"){
          data{
            id,
            order_type,
            user_id,
            amount,
            status,
            created_at,
            updated_at,
            promocode,
            target_bank{
              bank_name,
              owner_name,
              account,
              agency
            },
            tags{
              key,
              value
            },
            provider{
              code
            }
          },
        }
      }`,
      client: 'cashioClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
    confirmed_deposits:{
      query: gql `query($userId:ID, $containStatus:[String!]){
      confirmed_deposits: 
        orders(first:15,user_id:$userId, order_type:"DEPOSIT", containStatus:$containStatus){
          data{
            id,
            order_type,
            user_id,
            amount,
            status,
            created_at,
            updated_at,
            promocode,
            target_bank{
              bank_name,
              owner_name,
              account,
              agency
            },
            tags{
              key,
              value
            },
            provider{
              code
            }
          },
        }
      }`,
      client: 'cashioClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
          containStatus: ["CONFIRMED", "CONFIRMED_WITH_OBSERVATION", "ROLLEDBACK"]
        }
      },
    },
    user_groups_by_user_id:{
      query: gql `query($userId:ID){
        user_groups_by_user_id(user_id:$userId){
          id,
          name,
          members_count,
          created_at
        }
      }`,
      client: 'identityClient',
      variables () {
        return {
          userId: this.$route.params.clientId,
        }
      },
    },
  },
  watch:{
    wallets: function(){
      let obj = _.pluck(this.wallets.data, 'id')
      this.walletsToBeSearched = obj
    },
    user_groups_by_user_id:function(){
      this.user_groups = this.user_groups_by_user_id;
    },
    user_with_all_relations:function(){
      this.user = this.user_with_all_relations
      let plucky = _.pluck(this.user.user_relations, 'related_user').filter(r => r.id != this.user.id)
      this.relatedUsers = _.pluck(this.user.user_relations, 'related_to_me').filter(r => r.id != this.user.id)
      this.relatedUsers.push(...plucky)
    },

  },
  methods:{
    alterDoc(id,status){
      const docId = id;
      const docStatus = status;
      this.$apollo.mutate({
        mutation: gql`mutation (
          $id: ID!,
          $status: String,
        ) {
          updateUserDocument(
            id: $id,
            status: $status
          ){
            id
          }
        }`,
        variables: {
          id: docId,
          status:docStatus
        },
        client: "identityClient"
      }).then((data) => {
        this.$apolloProvider.clients.identityClient.reFetchObservableQueries()
      }).catch((error) => {
        console.error(error)
      })
    },
    openDepositSidebar(e,ref) {
      this.depositObj = e
      this.$refs[ref].toogleV()
    },
    refreshOrders(){
      this.$apolloProvider.clients.cashioClient.reFetchObservableQueries()
    },
    refreshIdentity(){
      this.$apolloProvider.clients.identityClient.reFetchObservableQueries()
    },
    openButtonSidebar(e){
      this.$refs[e].toogleV()

    },
    removeUserRelation(e){
      const res = this.$dialog.confirm({ title: 'Remover relação',text: 'Voce realmente gostaria de desvincular os usuários?', actions: [{text: 'Não',variant: 'red', key:false},{text: 'Sim, remover',variant: 'green', key:true}]}).then(function(valor) {
        if(valor === true){
          this.unsetRelation = e;
          this.$apollo.mutate({
            mutation: gql`mutation (
              $id: ID!,
            ){
              deleteUserRelation(
                id: $id,
              ){
                id
              }
            }`,
            variables: {
              id: this.unsetRelation,
            },
            client: "identityClient"
          }).then((data) => {
            this.$apolloProvider.clients.identityClient.reFetchObservableQueries()
          }).catch((error) => {
            console.error(error)
          })
        }
      }.bind(this), function(motivo) {
      // rejeitada
      });
    },
    showRelatedModal(){
      this.$refs['relatedModal'].$bvModal.show("user-related-modal")
    },
    showGroupModal(){
      this.$refs['groupModal'].$bvModal.show("user-group-modal")
    },
    refreshGroups(el_group){
      this.user_groups = el_group;
      this.rKey++;

    }
  },
}
</script>

<style lang="scss">
  .act-button{
    min-width: 130px;
    display: block;
    margin: 10px auto;
  }
  #blacklist-flag{
    background:#ea5455;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    margin: 10px 20px 0;
    font-size: 20px;
  }

  #good-client-flag{
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    font-family: "Century Gothic", "Helvetica", sans-serif;
    font-size: 25;
    font-weight: bold;
    background:radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 60%, transparent 80%),                
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62%, #5d4a1f 100%);
    margin: 10px 20px;
    position: relative;
    overflow: hidden;
    top: 25px;
  }
  #demo-client-flag{
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
    font-size: 25;
    font-weight: bold;
    background:transparent;
    border:1px solid yellow;
    margin: 10px 20px;
    position: relative;
    overflow: hidden;
    top: 25px;
    span{
      color:yellow;

    }
  }
  /* Darker text on medium background */
  .plate{ 
    color: transparent;
    text-shadow: 0px 1px 0px rgba(255,255,255,.5); /* 50% white from bottom */
  }
  #good-client-flag:after {
  animation: shine 5s ease-in-out  infinite;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 600%;
  height: 600%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.7) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.icon:active:after {
  opacity: 0;
}

@keyframes shine{
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.15s, 0.15s, 0.30s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
</style>
